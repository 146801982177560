var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forms-forms",class:_vm.rootClassName},[_c('div',{staticClass:"forms-box"},[_c('div',{staticClass:"forms-frame31"},[_c('h2',[_vm._t("text4",function(){return [_vm._m(0)]})],2),_c('p',[_vm._t("text11",function(){return [_vm._m(1)]})],2)]),_c('div',{staticClass:"forms-frame4"},[_c('h2',[_vm._t("text2",function(){return [_vm._m(2)]})],2),_c('div',{staticClass:"forms-frame5"},[_c('input',{staticClass:"forms-inputtext1",attrs:{"type":"text","id":"inputNome","name":"inputNome","pattern":"[A-Za-z\\s]+$","required":"true","placeholder":_vm.inputtextPlaceholder}}),_c('input',{staticClass:"forms-inputtext2",attrs:{"type":"text","id":"inputEmail","name":"inputEmail","pattern":"[a-z0-9\\._\\%\\+\\-]+@[a-z0-9\\.]+\\.[a-z]&#123;2,4&#125;$","required":"true","placeholder":_vm.inputtextPlaceholder1}}),_c('input',{staticClass:"forms-inputtext3",attrs:{"type":"text","id":"inputWhatstx","name":"inputWhatstx","onkeyup":"handlePhone(event)","required":"true","placeholder":_vm.inputtextPlaceholder2,"autocomplete":"handlePhone(event)"}}),_c('button',{staticClass:"forms-button",attrs:{"id":"ButtonCadastro","name":"ButtonCadastro","onclick":"cadastro()"}},[_c('span',[_vm._t("text3",function(){return [_vm._m(3)]})],2)])])])]),_c('div',{staticClass:"forms-frame32",attrs:{"id":"success"}},[_c('h2',[_vm._t("text41",function(){return [_vm._m(4)]})],2),_c('p',[_vm._t("text111",function(){return [_vm._m(5)]})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forms-fragment4"},[_c('h2',{staticClass:"forms-text23"},[_c('span',[_vm._v("Aviso por e-mail: gratuito.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forms-fragment6"},[_c('p',{staticClass:"forms-text26"},[_c('span',[_vm._v("Para outras opções consulte nossos planos.")]),_c('br'),_c('span')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forms-fragment2"},[_c('h2',{staticClass:"forms-text19"},[_c('span',[_vm._v("Me avise!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forms-fragment3"},[_c('span',{staticClass:"forms-text21"},[_c('span',[_vm._v("Enviar!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forms-fragment5"},[_c('h2',{staticClass:"forms-text25"},[_vm._v("Seu e-mail foi cadastrado com sucesso!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forms-fragment1"},[_c('p',{staticClass:"forms-text16"},[_c('span',[_vm._v("Você receberá um link de confirmação em breve.")]),_c('br')])])
}]

export { render, staticRenderFns }