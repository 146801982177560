<template>
  <div class="menu-menu" v-bind:class="rootClassName">
    <div class="menu-logo">
      <a href="https://fuinomeado.com/" class="menu-link1">
        <img
          alt="Logo"
          src="https://fuinomeado.com/static/image/LogoGrande.svg"
          class="menu-image"
        />
      </a>
    </div>
    <div class="menu-frame1">
      <div class="menu-frame2">
        <router-link to="/" class="menu-navlink1">
          <div id="BtPghome" class="menu-bt-home">
            <span>
              <slot name="text">
                <!--Default content for text-->
                <div class="menu-fragment2">
                  <span class="menu-text16"><span>Home</span></span>
                </div>
              </slot>
            </span>
          </div>
        </router-link>
        <router-link to="/planos" class="menu-navlink2">
          <div id="BtPgplano" class="menu-bt-planos">
            <span>
              <slot name="text1">
                <!--Default content for text1-->
                <div class="menu-fragment4">
                  <span class="menu-text20"><span>Planos</span></span>
                </div>
              </slot>
            </span>
          </div>
        </router-link>
        <router-link to="/contato" class="menu-navlink3">
          <div id="BtPgConntato" class="menu-bt-contato">
            <span>
              <slot name="text2">
                <!--Default content for text2-->
                <div class="menu-fragment3">
                  <span class="menu-text18"><span>Contato</span></span>
                </div>
              </slot>
            </span>
          </div>
        </router-link>
        <a href="https://fuinomeado.com/blog" class="menu-link2">
          <div id="BtPgBlog" class="menu-bt-bloq">
            <span>
              <slot name="text3">
                <!--Default content for text3-->
                <div class="menu-fragment1">
                  <span class="menu-text14"><span>Bloq</span></span>
                </div>
              </slot>
            </span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  props: {
    rootClassName: String,
  },
}
</script>

<style scoped>
.menu-menu {
  gap: 10px;
  width: 100%;
  height: 15vh;
  display: flex;
  padding: 10px;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 24px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 1);
}
.menu-logo {
  gap: 10px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
}
.menu-link1 {
  display: contents;
}
.menu-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-decoration: none;
}
.menu-frame1 {
  width: 70%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  margin-left: auto;
}
.menu-frame2 {
  gap: 20px;
  top: 60px;
  left: -10px;
  width: 100%;
  height: 50px;
  display: flex;
  padding: 0 10px;
  position: absolute;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0.00%,rgba(255, 255, 255, 0.8) 100.00%);
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: flex-end;
}
.menu-navlink1 {
  display: contents;
}
.menu-bt-home {
  gap: 10px;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  justify-content: flex-end;
  text-decoration: none;
}
.menu-navlink2 {
  display: contents;
}
.menu-bt-planos {
  gap: 10px;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  border-radius: 8px;
  justify-content: flex-end;
  text-decoration: none;
}
.menu-navlink3 {
  display: contents;
}
.menu-bt-contato {
  gap: 10px;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  justify-content: flex-end;
  text-decoration: none;
}
.menu-link2 {
  display: contents;
}
.menu-bt-bloq {
  gap: 10px;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  justify-content: flex-end;
  text-decoration: none;
}
.menu-fragment1 {
  display: contents;
}
.menu-text14 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.menu-fragment2 {
  display: contents;
}
.menu-text16 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.menu-fragment3 {
  display: contents;
}
.menu-text18 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.menu-fragment4 {
  display: contents;
}
.menu-text20 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}




@media(max-width: 991px) {
  .menu-image {
    height: 100%;
  }
}
@media(max-width: 767px) {
  .menu-image {
    height: 100%;
  }
}
@media(max-width: 479px) {
  .menu-menu {
    align-items: center;
    flex-direction: column;
  }
  .menu-logo {
    gap: 0;
    width: 100%;
    height: auto;
    align-items: center;
  }
  .menu-image {
    width: auto;
    height: 100%;
  }
  .menu-frame1 {
    width: 100%;
    height: auto;
    margin: 0px;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: center;
  }
  .menu-frame2 {
    top: 0px;
    width: 100%;
    height: 45px;
    padding: 0;
    position: static;
  }
  .menu-text14 {
    font-style: normal;
  }
  .menu-text16 {
    font-style: normal;
  }
  .menu-text18 {
    font-style: normal;
  }
  .menu-text20 {
    font-style: normal;
  }
}
</style>
