import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Home from './views/home'
import Contato from './views/contato'
import Comprapix from './views/comprapix'
import Planos from './views/planos'
import PlanoValido from './views/plano-valido'
import NotFound from './views/not-found'
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: 'Contato',
      path: '/contato',
      component: Contato,
    },
    {
      name: 'comprapix',
      path: '/comprapix',
      component: Comprapix,
    },
    {
      name: 'Planos',
      path: '/planos',
      component: Planos,
    },
    {
      name: 'Plano-Valido',
      path: '/plano-valido',
      component: PlanoValido,
    },
    {
      name: '404 - Not Found',
      path: '**',
      component: NotFound,
      fallback: true,
    },
  ],
})
