<template>
  <div class="footer-container1" v-bind:class="rootClassName">
    <div class="footer-container2">
      <span>
        <slot name="text">
          <!--Default content for text-->
          <div class="footer-fragment1">
            <span class="footer-text4">Text</span>
          </div>
        </slot>
      </span>
      <span>
        <slot name="text1">
          <!--Default content for text1-->
          <div class="footer-fragment2">
            <span class="footer-text5">
              © FuiNomeado.com tem todos os direitos reservados -
              <span v-html="rawz3az"></span>
            </span>
          </div>
        </slot>
      </span>
      <span href="static/PolíticaDePrivacidade-FuiNomeado.pdf">
        <slot name="text2">
          <!--Default content for text2-->
          <div class="footer-fragment3">
            <span class="footer-text6">Leia nossa política de privacidade</span>
          </div>
        </slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    rootClassName: String,
  },
  data() {
    return {
      rawisux: ' ',
      rawz3az: ' ',
    }
  },
}
</script>

<style scoped>
.footer-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}
.footer-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-self: center;
  align-items: flex-end;
  justify-content: center;
}
.footer-fragment1 {
  display: contents;
}
.footer-text4 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.footer-fragment2 {
  display: contents;
}
.footer-text5 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.footer-fragment3 {
  display: contents;
}
.footer-text6 {
  fill: gray;
  color: gray;
  display: inline-block;
}





</style>
