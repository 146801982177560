<template>
  <div class="forms-forms" v-bind:class="rootClassName">
    <div class="forms-box">
      <div class="forms-frame31">
        <h2>
          <slot name="text4">
            <!--Default content for text4-->
            <div class="forms-fragment4">
              <h2 class="forms-text23">
                <span>Aviso por e-mail: gratuito.</span>
              </h2>
            </div>
          </slot>
        </h2>
        <p>
          <slot name="text11">
            <!--Default content for text11-->
            <div class="forms-fragment6">
              <p class="forms-text26">
                <span>Para outras opções consulte nossos planos.</span>
                <br />
                <span></span>
              </p>
            </div>
          </slot>
        </p>
      </div>
      <div class="forms-frame4">
        <h2>
          <slot name="text2">
            <!--Default content for text2-->
            <div class="forms-fragment2">
              <h2 class="forms-text19"><span>Me avise!</span></h2>
            </div>
          </slot>
        </h2>
        <div class="forms-frame5">
          <input
            type="text"
            id="inputNome"
            name="inputNome"
            pattern="[A-Za-z\s]+$"
            required="true"
            :placeholder="inputtextPlaceholder"
            class="forms-inputtext1"
          />
          <input
            type="text"
            id="inputEmail"
            name="inputEmail"
            pattern="[a-z0-9\._\%\+\-]+@[a-z0-9\.]+\.[a-z]&#123;2,4&#125;$"
            required="true"
            :placeholder="inputtextPlaceholder1"
            class="forms-inputtext2"
          />
          <input
            type="text"
            id="inputWhatstx"
            name="inputWhatstx"
            onkeyup="handlePhone(event)"
            required="true"
            :placeholder="inputtextPlaceholder2"
            autocomplete="handlePhone(event)"
            class="forms-inputtext3"
          />
          <button
            id="ButtonCadastro"
            name="ButtonCadastro"
            onclick="cadastro()"
            class="forms-button"
          >
            <span>
              <slot name="text3">
                <!--Default content for text3-->
                <div class="forms-fragment3">
                  <span class="forms-text21"><span>Enviar!</span></span>
                </div>
              </slot>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div id="success" class="forms-frame32">
      <h2>
        <slot name="text41">
          <!--Default content for text41-->
          <div class="forms-fragment5">
            <h2 class="forms-text25">Seu e-mail foi cadastrado com sucesso!</h2>
          </div>
        </slot>
      </h2>
      <p>
        <slot name="text111">
          <!--Default content for text111-->
          <div class="forms-fragment1">
            <p class="forms-text16">
              <span>Você receberá um link de confirmação em breve.</span>
              <br />
            </p>
          </div>
        </slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Forms',
  props: {
    inputtextPlaceholder2: {
      type: String,
      default: 'Whatsapp',
    },
    rootClassName: String,
    inputtextPlaceholder1: {
      type: String,
      default: 'Email',
    },
    inputtextPlaceholder: {
      type: String,
      default: 'Nome',
    },
  },
}
</script>

<style scoped>
.forms-forms {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 10px;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  flex-direction: column;
  background-color: rgba(24, 97, 114, 1);
}
.forms-box {
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 10px;
  overflow: hidden;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
}
.forms-frame31 {
  gap: 10px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.forms-frame4 {
  gap: 10px;
  display: flex;
  padding: 10px;
  flex-grow: 1;
  align-self: stretch;
  background: linear-gradient(0deg, rgba(71, 137, 152, 1) 55%, rgba(24, 97, 114, 1) 100%);
  align-items: center;
  border-radius: 8px;
  flex-direction: column;
}
.forms-frame5 {
  gap: 20px;
  display: flex;
  padding: 20px;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.forms-inputtext1 {
  gap: 10px;
  color: rgba(119, 119, 119, 1);
  width: 100%;
  height: 30px;
  display: flex;
  padding: 10px;
  font-size: 12px;
  text-align: left;
  transition: 0.3s;
  align-items: flex-start;
  flex-shrink: 0;
  font-family: Inter;
  font-weight: 400;
  border-radius: 8px;
  flex-direction: column;
  background-color: rgba(223, 223, 223, 1);
}
.forms-inputtext1:focus {
  box-shadow: 2px 2px 3px 0px #444444;
}
.forms-inputtext1:disabled {
  fill: #8a8a8a;
  color: #8a8a8a;
}
.forms-inputtext2 {
  gap: 10px;
  color: rgba(119, 119, 119, 1);
  width: 100%;
  height: 30px;
  display: flex;
  padding: 10px;
  font-size: 12px;
  text-align: left;
  transition: 0.3s;
  align-items: flex-start;
  flex-shrink: 0;
  font-family: Inter;
  font-weight: 400;
  border-radius: 8px;
  flex-direction: column;
  background-color: rgba(223, 223, 223, 1);
}
.forms-inputtext2:focus {
  box-shadow: 2px 2px 3px 0px #444444;
}

.forms-inputtext3 {
  gap: 10px;
  color: rgba(119, 119, 119, 1);
  width: 100%;
  height: 30px;
  display: flex;
  padding: 10px;
  font-size: 12px;
  text-align: left;
  transition: 0.3s;
  align-items: flex-start;
  flex-shrink: 0;
  font-family: Inter;
  font-weight: 400;
  border-radius: 8px;
  flex-direction: column;
  background-color: rgba(223, 223, 223, 1);
}
.forms-inputtext3:focus {
  box-shadow: 2px 2px 3px 0px #444444;
}
.forms-inputtext3:disabled {
  color: #8a8a8a;
}
.forms-button {
  gap: 10px;
  width: 120px;
  display: flex;
  padding: 10px;
  transition: 0.3s;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  background-color: rgba(117, 174, 187, 1);
}
.forms-button:focus {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.forms-button:hover {
  background-color: #89ccda;
}
.forms-frame32 {
  gap: 10px;
  display: none;
  padding: 10px;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.forms-fragment1 {
  display: contents;
}
.forms-text16 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 15px;
  font-style: Semi Bold;
  margin-top: alto;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.forms-fragment2 {
  display: contents;
}
.forms-text19 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-size: 20px;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.forms-fragment3 {
  display: contents;
}
.forms-text21 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-size: 12px;
  font-style: Bold;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.forms-fragment4 {
  display: contents;
}
.forms-text23 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 24px;
  align-self: center;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.forms-fragment5 {
  display: contents;
}
.forms-text25 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 24px;
  align-self: center;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.forms-fragment6 {
  display: contents;
}
.forms-text26 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 15px;
  font-style: Semi Bold;
  margin-top: alto;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}


.formsroot-class-name2 {
  display: none;
}

@media(max-width: 1600px) {
  .forms-inputtext1 {
    color: #000000;
  }
  .forms-inputtext2 {
    color: #000000;
  }
  .forms-inputtext3 {
    color: #000000;
  }
  .forms-frame32 {
    width: 50%;
    display: none;
    border-color: rgba(8, 169, 8, 0.61);
    border-width: 5px;
    border-radius: var(--dl-radius-radius-buttonradius);
    background-color: rgba(217, 217, 217, 0.15);
  }
  .forms-text16 {
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 15px;
    text-align: center;
    font-weight: 700;
  }
  .forms-text25 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: Inter;
    font-weight: 700;
    line-height: 28px;
    text-decoration: none;
  }
}
@media(max-width: 991px) {
  .forms-forms {
    width: 90vW;
  }
  .formsroot-class-name1 {
    width: 100%;
    align-self: center;
  }
}
@media(max-width: 767px) {
  .forms-forms {
    flex-direction: column;
  }
  .forms-frame31 {
    width: 40%;
  }
  .formsroot-class-name1 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .forms-forms {
    flex-direction: column;
  }
  .forms-box {
    align-self: center;
    flex-direction: column;
  }
  .forms-frame31 {
    width: 100%;
    align-self: center;
  }
  .forms-frame32 {
    align-self: center;
  }
  .forms-text16 {
    font-size: small;
    font-weight: 500;
  }
  .forms-text25 {
    font-size: small;
    font-weight: 800;
  }
}
</style>
