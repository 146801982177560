<template>
  <div class="plano-valido-container1">
    <app-menu rootClassName="menuroot-class-name3">
      <template v-slot:text>
        <div class="plano-valido-fragment10">
          <span class="plano-valido-text10"><span>Home</span></span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="plano-valido-fragment11">
          <span class="plano-valido-text12"><span>Planos</span></span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="plano-valido-fragment12">
          <span class="plano-valido-text14"><span>Contato</span></span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="plano-valido-fragment13">
          <span class="plano-valido-text16"><span>Bloq</span></span>
        </div>
      </template>
    </app-menu>
    <h1 class="plano-valido-text18">Parabéns Seu Plano esta Ativo</h1>
    <div class="plano-valido-body">
      <h2 id="comprapix-heading" class="plano-valido-heading">
        Detalhes do Plano &#123;&#123;plano.title()&#125;&#125;
      </h2>
      <div id="comprapix-container" class="plano-valido-container2">
        <div class="plano-valido-container3">
          <div class="plano-valido-container4">
            <div class="plano-valido-container5">
              <h2 class="plano-valido-text19">O que esta incluso:</h2>
              <ul class="list">
                <li class="plano-valido-li1 list-item">
                  <span>Leitura Diária.</span>
                </li>
                <li class="plano-valido-li2 list-item">
                  <span>Aviso Por E-mail</span>
                </li>
                <li class="plano-valido-li3 list-item">
                  <span>Aviso por WhatsApp</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="plano-valido-container6">
          <h2 class="plano-valido-text23">Confirme o seus dados:</h2>
          <ul class="plano-valido-ul2 list">
            <li class="plano-valido-li4 list-item">
              <span>Nome Completo: </span>
              <span id="lb_nome">&#123;&#123;nome&#125;&#125;</span>
            </li>
            <li class="plano-valido-li5 list-item">
              <span>Email:</span>
              <span id="lb_email">&#123;&#123;nome&#125;&#125;</span>
            </li>
            <li class="plano-valido-li6 list-item">
              <span>WhatsApp:</span>
              <span id="lb_telefone"> &#123;&#123;telefone&#125;&#125;</span>
            </li>
            <li class="plano-valido-list-item list-item">
              <span>Validade:</span>
              <span id="lb_validade">&#123;&#123;validade&#125;&#125;</span>
            </li>
          </ul>
          <div class="plano-valido-container7">
            <button
              id="bt_forms"
              type="button"
              onclick="dados_incorretos()"
              class="plano-valido-button button"
            >
              Renovar Antecipado
            </button>
          </div>
        </div>
      </div>
      <app-forms rootClassName="formsroot-class-name3">
        <template v-slot:text2>
          <div class="plano-valido-fragment14">
            <span class="plano-valido-text32"><span>Me avise!</span></span>
          </div>
        </template>
        <template v-slot:text3>
          <div class="plano-valido-fragment15">
            <span class="plano-valido-text34"><span>Enviar!</span></span>
          </div>
        </template>
        <template v-slot:text4>
          <div class="plano-valido-fragment16">
            <span class="plano-valido-text36">
              <span> </span>
              <br />
            </span>
          </div>
        </template>
        <template v-slot:text11>
          <div class="plano-valido-fragment17">
            <span class="plano-valido-text39">
              <span>Por Favor corrija seus dados aqui.</span>
              <br />
            </span>
          </div>
        </template>
        <template v-slot:text41>
          <div class="plano-valido-fragment18">
            <span class="plano-valido-text42">
              Seu e-mail foi cadastrado com sucesso!
            </span>
          </div>
        </template>
        <template v-slot:text111>
          <div class="plano-valido-fragment19">
            <span class="plano-valido-text43">
              <span>Você receberá um link de confirmação em breve.</span>
              <br />
            </span>
          </div>
        </template>
      </app-forms>
    </div>
    <div>
      <div class="plano-valido-container9">
        <DangerousHTML
          html="    <script type='text/javascript' src='/static/js/mkt.js'></script>
      <script type='text/javascript' src='/static/js/pgpagamento.js'></script>"
        ></DangerousHTML>
      </div>
    </div>
    <app-footer rootClassName="footerroot-class-name4">
      <template v-slot:text>
        <div class="plano-valido-fragment20">
          <span class="plano-valido-text46">Text</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="plano-valido-fragment21">
          <span class="plano-valido-text47">
            © FuiNomeado.com tem todos os direitos reservados -
            <span v-html="rawmy2v"></span>
          </span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="plano-valido-fragment22">
          <span class="plano-valido-text48">
            Leia nossa política de privacidade
          </span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import AppMenu from '../components/menu'
import AppForms from '../components/forms'
import AppFooter from '../components/footer'

export default {
  name: 'PlanoValido',
  props: {},
  components: {
    AppMenu,
    AppForms,
    DangerousHTML,
    AppFooter,
  },
  data() {
    return {
      rawmy2v: ' ',
    }
  },
  metaInfo: {
    title: 'Plano-Valido - exported project',
    meta: [
      {
        property: 'og:title',
        content: 'Plano-Valido - exported project',
      },
    ],
  },
}
</script>

<style scoped>
.plano-valido-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: rgba(214, 214, 214, 0.15);
}
.plano-valido-fragment10 {
  display: contents;
}
.plano-valido-text10 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.plano-valido-fragment11 {
  display: contents;
}
.plano-valido-text12 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.plano-valido-fragment12 {
  display: contents;
}
.plano-valido-text14 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.plano-valido-fragment13 {
  display: contents;
}
.plano-valido-text16 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.plano-valido-text18 {
  color: var(--dl-color-fuinomeado-azul2);
}
.plano-valido-body {
  gap: 10px;
  width: 70%;
  height: auto;
  margin: 20px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.plano-valido-heading {
  color: var(--dl-color-fuinomeado-azul2);
}
.plano-valido-container2 {
  flex: 1;
  height: auto;
  display: flex;
  align-self: stretch;
  box-shadow: 0px 5px 10px 0px #d4d4d4;
  align-items: stretch;
  border-radius: 25px;
  justify-content: space-between;
  background-color: rgba(132, 179, 194, 0.26);
}
.plano-valido-container3 {
  flex: 0 0 auto;
  width: 48%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.plano-valido-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.plano-valido-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.plano-valido-text19 {
  fill: var(--dl-color-fuinomeado-azul2);
  color: var(--dl-color-fuinomeado-azul2);
}
.plano-valido-li1 {
  fill: var(--dl-color-fuinomeado-azul1);
  color: var(--dl-color-fuinomeado-azul1);
  padding: 2px;
  margin-top: 1px;
}
.plano-valido-li2 {
  fill: var(--dl-color-fuinomeado-azul1);
  color: var(--dl-color-fuinomeado-azul1);
  padding: 2px;
  margin-top: 1px;
}
.plano-valido-li3 {
  fill: var(--dl-color-fuinomeado-azul1);
  color: var(--dl-color-fuinomeado-azul1);
  padding: 2px;
  margin-top: 1px;
}
.plano-valido-container6 {
  flex: 0 0 auto;
  width: 48%;
  height: 100%;
  display: flex;
  box-shadow: 0px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgba(132, 179, 194, 0.3) 43.00%,rgba(78, 135, 149, 0.6) 99.00%);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.plano-valido-text23 {
  fill: var(--dl-color-fuinomeado-azul2);
  color: var(--dl-color-fuinomeado-azul2);
}
.plano-valido-ul2 {
  flex: 1;
  width: 100%;
}
.plano-valido-li4 {
  fill: var(--dl-color-fuinomeado-azul1);
  color: var(--dl-color-fuinomeado-azul1);
  padding: 2px;
  margin-top: 1px;
}
.plano-valido-li5 {
  fill: var(--dl-color-fuinomeado-azul1);
  color: var(--dl-color-fuinomeado-azul1);
  padding: 2px;
  margin-top: 1px;
}
.plano-valido-li6 {
  fill: var(--dl-color-fuinomeado-azul1);
  color: var(--dl-color-fuinomeado-azul1);
  padding: 2px;
  margin-top: 1px;
}
.plano-valido-list-item {
  fill: var(--dl-color-fuinomeado-azul1);
  color: var(--dl-color-fuinomeado-azul1);
  padding: 2px;
  margin-top: 1px;
}
.plano-valido-container7 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.plano-valido-button {
  color: rgb(255, 255, 255);
  background-color: var(--dl-color-fuinomeado-azul1);
}
.plano-valido-button:focus {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.plano-valido-button:hover {
  background-color: var(--dl-color-fuinomeado-azul);
}
.plano-valido-fragment14 {
  display: contents;
}
.plano-valido-text32 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-size: 20px;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.plano-valido-fragment15 {
  display: contents;
}
.plano-valido-text34 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-size: 12px;
  font-style: Bold;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.plano-valido-fragment16 {
  display: contents;
}
.plano-valido-text36 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 24px;
  align-self: center;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.plano-valido-fragment17 {
  display: contents;
}
.plano-valido-text39 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 15px;
  font-style: Semi Bold;
  margin-top: alto;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.plano-valido-fragment18 {
  display: contents;
}
.plano-valido-text42 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 24px;
  align-self: center;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.plano-valido-fragment19 {
  display: contents;
}
.plano-valido-text43 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 15px;
  font-style: Semi Bold;
  margin-top: alto;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.plano-valido-container9 {
  display: contents;
}
.plano-valido-fragment20 {
  display: contents;
}
.plano-valido-text46 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.plano-valido-fragment21 {
  display: contents;
}
.plano-valido-text47 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.plano-valido-fragment22 {
  display: contents;
}
.plano-valido-text48 {
  fill: gray;
  color: gray;
  display: inline-block;
}
@media(max-width: 1600px) {
  .plano-valido-text42 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: Inter;
    font-weight: 700;
    line-height: 28px;
    text-decoration: none;
  }
  .plano-valido-text43 {
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 15px;
    text-align: center;
    font-weight: 700;
  }
}
@media(max-width: 1200px) {
  .plano-valido-container4 {
    width: 100%;
    padding: 10px;
  }
  .plano-valido-container7 {
    justify-content: center;
  }
  .plano-valido-button {
    margin: var(--dl-space-space-halfunit);
    align-self: center;
  }
}
@media(max-width: 991px) {
  .plano-valido-body {
    height: auto;
  }
}
@media(max-width: 479px) {
  .plano-valido-text10 {
    font-style: normal;
  }
  .plano-valido-text12 {
    font-style: normal;
  }
  .plano-valido-text14 {
    font-style: normal;
  }
  .plano-valido-text16 {
    font-style: normal;
  }
  .plano-valido-body {
    width: 90%;
  }
  .plano-valido-container2 {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .plano-valido-container3 {
    width: 100%;
  }
  .plano-valido-container6 {
    width: 100%;
  }
  .plano-valido-text42 {
    font-size: small;
    font-weight: 800;
  }
  .plano-valido-text43 {
    font-size: small;
    font-weight: 500;
  }
}
</style>
