<template>
  <div class="contato-container1">
    <div class="contato-contato">
      <div class="contato-body">
        <app-menu>
          <template v-slot:text>
            <div class="contato-fragment1">
              <span class="contato-text10"><span>Home</span></span>
            </div>
          </template>
          <template v-slot:text1>
            <div class="contato-fragment2">
              <span class="contato-text12"><span>Planos</span></span>
            </div>
          </template>
          <template v-slot:text2>
            <div class="contato-fragment3">
              <span class="contato-text14"><span>Contato</span></span>
            </div>
          </template>
          <template v-slot:text3>
            <div class="contato-fragment4">
              <span class="contato-text16"><span>Bloq</span></span>
            </div>
          </template>
        </app-menu>
        <div class="contato-h1">
          <span class="contato-text18"><span>Contatos</span></span>
        </div>
        <div class="contato-cards">
          <div class="contato-card1">
            <div class="contato-h21">
              <h3 class="contato-text20"><span>Quem Somos</span></h3>
            </div>
            <div class="contato-frame61">
              <span class="contato-text22">
                <span>
                  A AYA tech é uma empresa especializada em Open Data e leitura de
                  informações públicas. 
                </span>
              </span>
              <span class="contato-text24">
                <span>
                  Capturamos dados de diversas fontes públicas e disponibilizamos
                  em forma de serviços para pessoas físicas e jurídicas no mercado
                  B2C e B2B. 
                </span>
              </span>
            </div>
          </div>
          <div class="contato-card2">
            <div class="contato-h22">
              <h3 class="contato-text26"><span>O Que Oferecemos</span></h3>
            </div>
            <div class="contato-frame62">
              <span class="contato-text28">
                <span>
                  Sabemos que passar em um concurso é o maior sonho de muitas
                  pessoas.
                </span>
              </span>
              <span class="contato-text30">
                <span>
                  Imagine ser nomeado e não saber da nomeação e acabar perdendo o
                  prazo para a posse?
                </span>
              </span>
              <span class="contato-text32">
                <span>
                  Esse site surgiu para livrar você, futuro servidor público, de
                  ter que entrar todos os dias no diário oficial
                </span>
              </span>
            </div>
          </div>
          <div class="contato-card3">
            <div class="contato-h23">
              <h3 class="contato-text34"><span>Fale Conosco</span></h3>
            </div>
            <div class="contato-frame63">
              <span class="contato-text36">
                <span>Entre em contato conosco em:</span>
              </span>
              <div class="contato-frame13">
                <span class="contato-text38">
                  <span>contato@fuinomeado.com</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-footer rootClassName="footerroot-class-name1">
        <template v-slot:text>
          <div class="contato-fragment5">
            <span class="contato-text40">Text</span>
          </div>
        </template>
        <template v-slot:text1>
          <div class="contato-fragment6">
            <span class="contato-text41">
              © FuiNomeado.com tem todos os direitos reservados -
              <span v-html="raw36ro"></span>
            </span>
          </div>
        </template>
        <template v-slot:text2>
          <div class="contato-fragment7">
            <span class="contato-text42">Leia nossa política de privacidade</span>
          </div>
        </template>
      </app-footer>
    </div>
    <div>
      <div class="contato-container3">
        <DangerousHTML
          html="<script>
  function st(){
      document.getElementById('BtPgConntato').style.backgroundColor = '#4789985a';
      };
  st()
  </script>"
        ></DangerousHTML>
      </div>
    </div>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import AppMenu from '../components/menu'
import AppFooter from '../components/footer'

export default {
  name: 'Contato',
  components: {
    AppMenu,
    AppFooter,
    DangerousHTML,
  },
  data() {
    return {
      raw36ro: ' ',
    }
  },
  metaInfo: {
    title: 'exported project',
  },
}
</script>

<style scoped>
.contato-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contato-contato {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 10px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.contato-body {
  gap: 30px;
  width: 1076px;
  height: 831px;
  display: flex;
  padding: 20px 10px;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.contato-fragment1 {
  display: contents;
}
.contato-text10 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.contato-fragment2 {
  display: contents;
}
.contato-text12 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.contato-fragment3 {
  display: contents;
}
.contato-text14 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.contato-fragment4 {
  display: contents;
}
.contato-text16 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.contato-h1 {
  gap: 10px;
  display: flex;
  padding: 10px 44px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.contato-text18 {
  color: rgba(24, 97, 114, 1);
  height: auto;
  font-size: 24px;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.contato-cards {
  gap: 2%;
  width: 100%;
  height: auto;
  display: flex;
  padding: 20px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 10px;
  justify-content: center;
  background-color: rgba(24, 97, 114, 1);
}
.contato-card1 {
  gap: 30px;
  width: 30%;
  height: 100%;
  display: flex;
  padding: 20px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  flex-direction: column;
  background-color: rgba(117, 174, 187, 1);
}
.contato-h21 {
  gap: 10px;
  width: 100%;
  height: 5vw;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(24, 97, 114, 1);
}
.contato-text20 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 800;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.contato-frame61 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.contato-text22 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: Inter;
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.contato-text24 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: Inter;
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.contato-card2 {
  gap: 10px;
  width: 30%;
  height: 100%;
  display: flex;
  padding: 20px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  flex-direction: column;
  background-color: rgba(117, 174, 187, 1);
}
.contato-h22 {
  gap: 10px;
  width: 100%;
  height: 5vw;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(24, 97, 114, 1);
}
.contato-text26 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.contato-frame62 {
  gap: 10px;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.contato-text28 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: Inter;
  font-weight: 400;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.contato-text30 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: Inter;
  font-weight: 400;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.contato-text32 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: Inter;
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.contato-card3 {
  gap: 10px;
  width: 30%;
  height: 100%;
  display: flex;
  padding: 20px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  flex-direction: column;
  background-color: rgba(117, 174, 187, 1);
}
.contato-h23 {
  gap: 10px;
  width: 100%;
  height: 5vw;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(24, 97, 114, 1);
}
.contato-text34 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 600;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.contato-frame63 {
  gap: 10px;
  height: 219px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.contato-text36 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: Inter;
  font-weight: 600;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.contato-frame13 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 15px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4000000059604645);
}
.contato-text38 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  flex-grow: 1;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 400;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.contato-fragment5 {
  display: contents;
}
.contato-text40 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.contato-fragment6 {
  display: contents;
}
.contato-text41 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.contato-fragment7 {
  display: contents;
}
.contato-text42 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.contato-container3 {
  display: contents;
}
@media(max-width: 1600px) {
  .contato-body {
    width: 100%;
    height: auto;
  }
  .contato-text26 {
    font-style: normal;
    font-weight: 800;
  }
  .contato-text34 {
    font-style: normal;
    font-weight: 800;
  }
}
@media(max-width: 767px) {
  .contato-cards {
    margin: var(--dl-space-space-halfunit);
    flex-direction: column;
  }
  .contato-card1 {
    width: auto;
    margin: 10px;
  }
  .contato-card2 {
    width: 100%;
    margin: 10px;
  }
  .contato-card3 {
    width: 100%;
    margin: 10px;
  }
}
@media(max-width: 479px) {
  .contato-container1 {
    background-color: #D9D9D9;
  }
  .contato-text10 {
    font-style: normal;
  }
  .contato-text12 {
    font-style: normal;
  }
  .contato-text14 {
    font-style: normal;
  }
  .contato-text16 {
    font-style: normal;
  }
  .contato-cards {
    flex-direction: column;
    justify-content: flex-start;
  }
  .contato-card1 {
    width: 100%;
    margin: 10px;
  }
  .contato-card2 {
    width: 100%;
    margin: 10px;
  }
  .contato-card3 {
    width: 100%;
    margin: 10px;
  }
}
</style>
