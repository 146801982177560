<template>
  <div class="bx-planos-bx-planos-compra" v-bind:class="rootClassName">
    <div class="bx-planos-frame11">
      <div class="bx-planos-card11 bx_card_compra">
        <div class="card_plano bx-planos-card12">
          <div class="bx-planos-h21">
            <h3>
              <slot name="text21">
                <!--Default content for text21-->
                <div class="bx-planos-fragment10">
                  <h3 class="bx-planos-text24"><span>Gratuito</span></h3>
                </div>
              </slot>
            </h3>
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt15" :src="vectorSrc15" class="icon_plano" />
            <span>
              <slot name="text31">
                <!--Default content for text31-->
                <div class="bx-planos-fragment13">
                  <span class="bx-planos-text30 itens text_plano">
                    <span>Email</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt611" :src="vectorSrc611" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="unionAlt3" :src="unionSrc3" class="icon_plano" />
            <span>
              <slot name="text41">
                <!--Default content for text41-->
                <div class="bx-planos-fragment16">
                  <span class="bx-planos-text36 itens text_plano">
                    <span>WhatsApp</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt21" :src="vectorSrc21" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt31" :src="vectorSrc31" class="icon_plano" />
            <span>
              <slot name="text51">
                <!--Default content for text51-->
                <div class="bx-planos-fragment23">
                  <span class="bx-planos-text50 itens text_plano">Ligação</span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt41" :src="vectorSrc41" class="icon_plano" />
          </div>
        </div>
      </div>
      <div class="bx-planos-card21 bx_card_compra">
        <div class="bx-planos-card22 card_plano">
          <div class="bx-planos-h22">
            <h3>
              <slot name="text61">
                <!--Default content for text61-->
                <div class="bx-planos-fragment22">
                  <h3 class="bx-planos-text48"><span>Premium</span></h3>
                </div>
              </slot>
            </h3>
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt51" :src="vectorSrc51" class="icon_plano" />
            <span>
              <slot name="text71">
                <!--Default content for text71-->
                <div class="bx-planos-fragment20">
                  <span class="bx-planos-text44 itens text_plano">
                    <span>Email</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt61" :src="vectorSrc61" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="unionAlt11" :src="unionSrc11" class="icon_plano" />
            <span>
              <slot name="text81">
                <!--Default content for text81-->
                <div class="bx-planos-fragment14">
                  <span class="bx-planos-text32 itens text_plano">
                    <span>WhatsApp</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt71" :src="vectorSrc71" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt81" :src="vectorSrc81" class="icon_plano" />
            <span>
              <slot name="text91">
                <!--Default content for text91-->
                <div class="bx-planos-fragment21">
                  <span class="bx-planos-text46 itens text_plano">
                    <span>Ligação</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt91" :src="vectorSrc91" class="icon_plano" />
          </div>
          <div class="list_plano">
            <span>
              <slot name="text1411">
                <!--Default content for text1411-->
                <div class="bx-planos-fragment19">
                  <span class="bx-planos-text42 text_price">
                    <span>R$ 1,00 / Mês</span>
                  </span>
                </div>
              </slot>
            </span>
          </div>
        </div>
      </div>
      <div class="bx-planos-card31 bx_card_compra">
        <div class="card_plano bx-planos-card32">
          <div class="bx-planos-h23">
            <h3>
              <slot name="text101">
                <!--Default content for text101-->
                <div class="bx-planos-fragment12">
                  <h3 class="bx-planos-text28"><span>Plus</span></h3>
                </div>
              </slot>
            </h3>
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt101" :src="vectorSrc101" class="icon_plano" />
            <span>
              <slot name="text111">
                <!--Default content for text111-->
                <div class="bx-planos-fragment18">
                  <span class="bx-planos-text40 itens text_plano">
                    <span>Email</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt111" :src="vectorSrc111" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="unionAlt21" :src="unionSrc21" class="icon_plano" />
            <span>
              <slot name="text121">
                <!--Default content for text121-->
                <div class="bx-planos-fragment11">
                  <span class="bx-planos-text26 itens text_plano">
                    <span>WhatsApp</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt121" :src="vectorSrc121" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt131" :src="vectorSrc131" class="icon_plano" />
            <span>
              <slot name="text131">
                <!--Default content for text131-->
                <div class="bx-planos-fragment15">
                  <span class="bx-planos-text34 itens text_plano">
                    <span>Ligação</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt141" :src="vectorSrc141" class="icon_plano" />
          </div>
          <div class="list_plano">
            <span>
              <slot name="text151">
                <!--Default content for text151-->
                <div class="bx-planos-fragment17">
                  <span class="bx-planos-text38 text_price">
                    <span>R$ 50,00 / Mês</span>
                  </span>
                </div>
              </slot>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BxPlanos',
  props: {
    vectorSrc31: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/85370a67-db92-451d-bc28-f5d9a541d3e1?org_if_sml=1608&force_format=original',
    },
    vectorSrc1: {
      type: String,
      default:
        'https://play.teleporthq.io/static/svg/placeholders/no-image.svg',
    },
    vectorSrc121: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/c61cacb3-c114-4ca1-8ffc-9fc5b91a0f1a?org_if_sml=1459&force_format=original',
    },
    unionAlt21: {
      type: String,
      default: 'Union2024',
    },
    vectorAlt41: {
      type: String,
      default: 'Vector2024',
    },
    vectorAlt141: {
      type: String,
      default: 'Vector2024',
    },
    vectorAlt111: {
      type: String,
      default: 'Vector2024',
    },
    vectorSrc21: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/339b6ed0-ee75-4909-b484-abbb36a92995?org_if_sml=1670&force_format=original',
    },
    vectorSrc71: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/cbfcde8d-5210-404e-9abb-e15bec9faa0a?org_if_sml=1459&force_format=original',
    },
    vectorAlt21: {
      type: String,
      default: 'Vector2024',
    },
    vectorAlt71: {
      type: String,
      default: 'Vector2024',
    },
    vectorAlt81: {
      type: String,
      default: 'Vector2024',
    },
    vectorAlt101: {
      type: String,
      default: 'Vector2024',
    },
    unionAlt11: {
      type: String,
      default: 'Union2024',
    },
    vectorSrc: {
      type: String,
      default:
        'https://play.teleporthq.io/static/svg/placeholders/no-image.svg',
    },
    vectorAlt15: {
      type: String,
      default: 'Vector2024',
    },
    vectorSrc15: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/9a41b210-c160-4f65-b8a8-d7b7aac380fe?org_if_sml=1428&force_format=original',
    },
    vectorAlt121: {
      type: String,
      default: 'Vector2024',
    },
    vectorSrc141: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/094fb948-a5a0-4a97-8df2-156b22163d6a?org_if_sml=1459&force_format=original',
    },
    vectorAlt31: {
      type: String,
      default: 'Vector2024',
    },
    unionSrc11: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/bb36f3ac-43c3-4432-a968-fa9bc8381224?org_if_sml=12526&force_format=original',
    },
    vectorAlt131: {
      type: String,
      default: 'Vector2024',
    },
    vectorSrc111: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/20ef369d-71f6-4f97-80c8-35d946b14c3b?org_if_sml=1459&force_format=original',
    },
    vectorSrc131: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/ae6010fb-f836-4630-9127-bdfb472af839?org_if_sml=1588&force_format=original',
    },
    vectorAlt61: {
      type: String,
      default: 'Vector2024',
    },
    unionAlt3: {
      type: String,
      default: 'Union2024',
    },
    vectorSrc51: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/c9cc96c7-e610-4fb0-8920-f2911763e53a?org_if_sml=1428&force_format=original',
    },
    vectorSrc61: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/0ca5256b-fa01-43b3-b202-2e20fba9b2db?org_if_sml=1459&force_format=original',
    },
    vectorAlt91: {
      type: String,
      default: 'Vector2024',
    },
    unionSrc3: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/4d06c892-1094-4493-9207-d252d823a6c8?org_if_sml=12526&force_format=original',
    },
    vectorSrc101: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/c3bc07e2-03fe-43cf-8c66-5d5b5fac598b?org_if_sml=1428&force_format=original',
    },
    rootClassName: String,
    vectorSrc81: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/3f637c5b-5839-4f0e-a3ce-19875fda377d?org_if_sml=1588&force_format=original',
    },
    vectorSrc91: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/756995e7-f615-4e9a-a050-7cc8d155cdbf?org_if_sml=1670&force_format=original',
    },
    vectorAlt51: {
      type: String,
      default: 'Vector2024',
    },
    unionSrc21: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/40573c5f-f846-48f1-8000-32c41bf10354?org_if_sml=12526&force_format=original',
    },
    vectorSrc41: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/a3734797-32d7-4aa6-ba51-93183775a208?org_if_sml=1663&force_format=original',
    },
    vectorAlt611: {
      type: String,
      default: 'Vector2024',
    },
    vectorSrc611: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/0ca5256b-fa01-43b3-b202-2e20fba9b2db?org_if_sml=1459&force_format=original',
    },
  },
}
</script>

<style scoped>
.bx-planos-bx-planos-compra {
  gap: 10px;
  width: 100%;
  height: 322px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  flex-direction: column;
  background-color: rgba(117, 174, 187, 0.30000001192092896);
}
.bx-planos-frame11 {
  width: 100%;
  display: flex;
}
.bx-planos-card11 {
  height: auto;
  margin: auto;
}
.bx-planos-h21 {
  gap: 10px;
  width: 100%;
  height: 25%;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-card21 {
  height: auto;
  margin: auto;
}
.bx-planos-card22 {
  background-color: rgba(71, 137, 152, 1);
}
.bx-planos-h22 {
  gap: 10px;
  width: 100%;
  height: 25%;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-card31 {
  height: auto;
  margin: auto;
}
.bx-planos-h23 {
  gap: 10px;
  width: 100%;
  height: 25%;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-fragment10 {
  display: contents;
}
.bx-planos-text24 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  font-stretch: normal;
  text-decoration: none;
}
.bx-planos-fragment11 {
  display: contents;
}
.bx-planos-text26 {
  display: inline-block;
}
.bx-planos-fragment12 {
  display: contents;
}
.bx-planos-text28 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.bx-planos-fragment13 {
  display: contents;
}
.bx-planos-text30 {
  display: inline-block;
}
.bx-planos-fragment14 {
  display: contents;
}
.bx-planos-text32 {
  display: inline-block;
}
.bx-planos-fragment15 {
  display: contents;
}
.bx-planos-text34 {
  display: inline-block;
  font-stretch: normal;
}
.bx-planos-fragment16 {
  display: contents;
}
.bx-planos-text36 {
  display: inline-block;
}
.bx-planos-fragment17 {
  display: contents;
}
.bx-planos-text38 {
  display: inline-block;
}
.bx-planos-fragment18 {
  display: contents;
}
.bx-planos-text40 {
  display: inline-block;
}
.bx-planos-fragment19 {
  display: contents;
}
.bx-planos-text42 {
  display: inline-block;
}
.bx-planos-fragment20 {
  display: contents;
}
.bx-planos-text44 {
  display: inline-block;
}
.bx-planos-fragment21 {
  display: contents;
}
.bx-planos-text46 {
  display: inline-block;
}
.bx-planos-fragment22 {
  display: contents;
}
.bx-planos-text48 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.bx-planos-fragment23 {
  display: contents;
}
.bx-planos-text50 {
  display: inline-block;
}

@media(max-width: 479px) {
  .bx-planos-frame11 {
    height: 100%;
    padding: 10px;
    flex-direction: row;
  }
  .bx-planos-card11 {
    width: auto;
    height: 100%;
  }
  .bx-planos-card12 {
    width: 30vw;
    padding: 10px;
  }
  .bx-planos-card21 {
    width: auto;
    height: 100%;
  }
  .bx-planos-card22 {
    width: 30vw;
    padding: 10px;
  }
  .bx-planos-card31 {
    width: auto;
    height: 100%;
  }
  .bx-planos-card32 {
    width: 30vw;
    padding: 10px;
  }
  .bx-planos-text38 {
    margin: auto;
  }
}
</style>
