<template>
  <div class="home-container1">
    <div class="home-home">
      <app-menu rootClassName="menuroot-class-name">
        <template v-slot:text>
          <div class="home-fragment10">
            <span class="home-text10"><span>Home</span></span>
          </div>
        </template>
        <template v-slot:text1>
          <div class="home-fragment11">
            <span class="home-text12"><span>Planos</span></span>
          </div>
        </template>
        <template v-slot:text2>
          <div class="home-fragment12">
            <span class="home-text14"><span>Contato</span></span>
          </div>
        </template>
        <template v-slot:text3>
          <div class="home-fragment13">
            <span class="home-text16"><span>Bloq</span></span>
          </div>
        </template>
      </app-menu>
      <div class="home-body">
        <div class="home-texto">
          <h1 class="home-text18">
            <span>Fui nomeado em algum concurso?</span>
            <br />
            <span></span>
          </h1>
          <p class="home-text22">
            <span>
              Deixe seu nome e e-mail conosco e pesquisaremos todos os dias o
              diário oficial para saber se você foi nomeado. Assim que seu nome
              for publicado te enviamos um e-mail avisando.
            </span>
          </p>
        </div>
        <app-forms rootClassName="formsroot-class-name1">
          <template v-slot:text2>
            <div class="home-fragment14">
              <span class="home-text24"><span>Me avise!</span></span>
            </div>
          </template>
          <template v-slot:text3>
            <div class="home-fragment15">
              <span class="home-text26"><span>Enviar!</span></span>
            </div>
          </template>
          <template v-slot:text4>
            <div class="home-fragment16">
              <span class="home-text28">
                <span>Aviso por e-mail: gratuito.</span>
              </span>
            </div>
          </template>
          <template v-slot:text11>
            <div class="home-fragment17">
              <span class="home-text30">
                <span>Para outras opções consulte nossos planos.</span>
                <br />
                <span></span>
              </span>
            </div>
          </template>
          <template v-slot:text41>
            <div class="home-fragment18">
              <span class="home-text34">
                Seu e-mail foi cadastrado com sucesso!
              </span>
            </div>
          </template>
          <template v-slot:text111>
            <div class="home-fragment19">
              <span class="home-text35">
                <span>Você receberá um link de confirmação em breve.</span>
                <br />
              </span>
            </div>
          </template>
        </app-forms>
        <bx-planos-compra rootClassName="bx-planos-compraroot-class-name1">
          <template v-slot:text16>
            <div class="home-fragment20">
              <span class="home-text38 itens"><span>Eu Quero!!</span></span>
            </div>
          </template>
          <template v-slot:text17>
            <div class="home-fragment21">
              <span class="home-text40 itens"><span>Eu Quero!!</span></span>
            </div>
          </template>
          <template v-slot:text21>
            <div class="home-fragment22">
              <span class="home-text42"><span>Gratuito</span></span>
            </div>
          </template>
          <template v-slot:text31>
            <div class="home-fragment23">
              <span class="home-text44 itens text_plano"><span>Email</span></span>
            </div>
          </template>
          <template v-slot:text41>
            <div class="home-fragment24">
              <span class="home-text46 itens text_plano">
                <span>WhatsApp</span>
              </span>
            </div>
          </template>
          <template v-slot:text51>
            <div class="home-fragment25">
              <span class="home-text48 itens text_plano">Ligação</span>
            </div>
          </template>
          <template v-slot:text61>
            <div class="home-fragment26">
              <span class="home-text49"><span>Premium</span></span>
            </div>
          </template>
          <template v-slot:text71>
            <div class="home-fragment27">
              <span class="home-text51 itens text_plano"><span>Email</span></span>
            </div>
          </template>
          <template v-slot:text81>
            <div class="home-fragment28">
              <span class="home-text53 itens text_plano">
                <span>WhatsApp</span>
              </span>
            </div>
          </template>
          <template v-slot:text91>
            <div class="home-fragment29">
              <span class="home-text55 itens text_plano">
                <span>Ligação</span>
              </span>
            </div>
          </template>
          <template v-slot:text101>
            <div class="home-fragment30">
              <span class="home-text57"><span>Plus</span></span>
            </div>
          </template>
          <template v-slot:text111>
            <div class="home-fragment31">
              <span class="home-text59 itens text_plano"><span>Email</span></span>
            </div>
          </template>
          <template v-slot:text121>
            <div class="home-fragment32">
              <span class="home-text61 itens text_plano">
                <span>WhatsApp</span>
              </span>
            </div>
          </template>
          <template v-slot:text131>
            <div class="home-fragment33">
              <span class="home-text63 itens text_plano">
                <span>Ligação</span>
              </span>
            </div>
          </template>
          <template v-slot:text141>
            <div class="home-fragment34">
              <span class="home-text65 text_price">
                <span>R$ 1,00 / Mês</span>
              </span>
            </div>
          </template>
          <template v-slot:text151>
            <div class="home-fragment35">
              <span class="home-text67 text_price">
                <span>R$ 50,00 / Mês</span>
              </span>
            </div>
          </template>
        </bx-planos-compra>
      </div>
      <app-footer rootClassName="footerroot-class-name">
        <template v-slot:text>
          <div class="home-fragment36"><span class="home-text69">Text</span></div>
        </template>
        <template v-slot:text1>
          <div class="home-fragment37">
            <span class="home-text70">
              © FuiNomeado.com tem todos os direitos reservados -
              <span v-html="raw8n6h"></span>
            </span>
          </div>
        </template>
        <template v-slot:text2>
          <div class="home-fragment38">
            <span class="home-text71">Leia nossa política de privacidade</span>
          </div>
        </template>
      </app-footer>
    </div>
    <div>
      <div class="home-container3">
        <DangerousHTML
          html="<script>
  function st(){
      document.getElementById('BtPghome').style.backgroundColor = '#4789985a';
      };
  st()
  </script>"
        ></DangerousHTML>
      </div>
    </div>
    <div>
      <div class="home-container5">
        <DangerousHTML
          html="  <script type='text/javascript' src = './static/js/index.js'></script>
  "
        ></DangerousHTML>
      </div>
    </div>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import AppMenu from '../components/menu'
import AppForms from '../components/forms'
import BxPlanosCompra from '../components/bx-planos-compra'
import AppFooter from '../components/footer'

export default {
  name: 'Home',
  components: {
    AppMenu,
    AppForms,
    BxPlanosCompra,
    AppFooter,
    DangerousHTML,
  },
  data() {
    return {
      raw8n6h: ' ',
    }
  },
  metaInfo: {
    title: 'exported project',
  },
}
</script>

<style scoped>
.home-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-home {
  gap: 10px;
  width: auto;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  padding-top: 10px;
  padding-left: 0%;
  padding-right: 0%;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: rgba(255, 255, 255, 1);
}
.home-fragment10 {
  display: contents;
}
.home-text10 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.home-fragment11 {
  display: contents;
}
.home-text12 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.home-fragment12 {
  display: contents;
}
.home-text14 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.home-fragment13 {
  display: contents;
}
.home-text16 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.home-body {
  gap: 10px;
  width: 70%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.home-texto {
  gap: 10px;
  width: 100%;
  height: 16vh;
  display: flex;
  padding: 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  background-color: rgba(223, 223, 223, 1);
}
.home-text18 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  font-size: 24px;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.home-text22 {
  color: rgba(62, 62, 62, 1);
  width: 100%;
  height: auto;
  font-size: 20px;
  font-style: Medium;
  text-align: center;
  font-family: Inter;
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.home-fragment14 {
  display: contents;
}
.home-text24 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-size: 20px;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.home-fragment15 {
  display: contents;
}
.home-text26 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-size: 12px;
  font-style: Bold;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.home-fragment16 {
  display: contents;
}
.home-text28 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 24px;
  align-self: center;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.home-fragment17 {
  display: contents;
}
.home-text30 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 15px;
  font-style: Semi Bold;
  margin-top: alto;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.home-fragment18 {
  display: contents;
}
.home-text34 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 24px;
  align-self: center;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.home-fragment19 {
  display: contents;
}
.home-text35 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 15px;
  font-style: Semi Bold;
  margin-top: alto;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.home-fragment20 {
  display: contents;
}
.home-text38 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  text-align: center;
  font-stretch: normal;
}
.home-fragment21 {
  display: contents;
}
.home-text40 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  text-align: center;
  font-stretch: normal;
}
.home-fragment22 {
  display: contents;
}
.home-text42 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  font-stretch: normal;
  text-decoration: none;
}
.home-fragment23 {
  display: contents;
}
.home-text44 {
  display: inline-block;
}
.home-fragment24 {
  display: contents;
}
.home-text46 {
  display: inline-block;
}
.home-fragment25 {
  display: contents;
}
.home-text48 {
  display: inline-block;
}
.home-fragment26 {
  display: contents;
}
.home-text49 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.home-fragment27 {
  display: contents;
}
.home-text51 {
  display: inline-block;
}
.home-fragment28 {
  display: contents;
}
.home-text53 {
  display: inline-block;
}
.home-fragment29 {
  display: contents;
}
.home-text55 {
  display: inline-block;
}
.home-fragment30 {
  display: contents;
}
.home-text57 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.home-fragment31 {
  display: contents;
}
.home-text59 {
  display: inline-block;
}
.home-fragment32 {
  display: contents;
}
.home-text61 {
  display: inline-block;
}
.home-fragment33 {
  display: contents;
}
.home-text63 {
  display: inline-block;
  font-stretch: normal;
}
.home-fragment34 {
  display: contents;
}
.home-text65 {
  display: inline-block;
}
.home-fragment35 {
  display: contents;
}
.home-text67 {
  display: inline-block;
}
.home-fragment36 {
  display: contents;
}
.home-text69 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.home-fragment37 {
  display: contents;
}
.home-text70 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.home-fragment38 {
  display: contents;
}
.home-text71 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.home-container3 {
  display: contents;
}
.home-container5 {
  display: contents;
}
@media(max-width: 1600px) {
  .home-text34 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: Inter;
    font-weight: 700;
    line-height: 28px;
    text-decoration: none;
  }
  .home-text35 {
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 15px;
    text-align: center;
    font-weight: 700;
  }
}
@media(max-width: 991px) {
  .home-body {
    height: auto;
  }
  .home-texto {
    width: auto;
    height: auto;
    padding: 20px;
  }
}
@media(max-width: 767px) {
  .home-text38 {
    font-size: large;
  }
  .home-text40 {
    font-size: large;
  }
}
@media(max-width: 479px) {
  .home-container1 {
    background-color: #D9D9D9;
  }
  .home-text10 {
    font-style: normal;
  }
  .home-text12 {
    font-style: normal;
  }
  .home-text14 {
    font-style: normal;
  }
  .home-text16 {
    font-style: normal;
  }
  .home-body {
    width: 90%;
  }
  .home-text34 {
    font-size: small;
    font-weight: 800;
  }
  .home-text35 {
    font-size: small;
    font-weight: 500;
  }
  .home-text38 {
    width: auto;
    height: 100%;
  }
  .home-text67 {
    margin: auto;
  }
}
</style>
