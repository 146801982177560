<template>
  <div
    id="BxPlanosCompra"
    class="bx-planos-compra-bx-planos-compra"
    v-bind:class="rootClassName"
  >
    <div class="bx-planos-compra-frame11">
      <div class="bx_card_compra bx-planos-compra-card11">
        <div class="card_plano">
          <div class="bx-planos-compra-h21">
            <h3>
              <slot name="text21">
                <!--Default content for text21-->
                <div class="bx-planos-compra-fragment19">
                  <h3 class="bx-planos-compra-text43"><span>Gratuito</span></h3>
                </div>
              </slot>
            </h3>
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt15" :src="vectorSrc15" class="icon_plano" />
            <span>
              <slot name="text31">
                <!--Default content for text31-->
                <div class="bx-planos-compra-fragment13">
                  <span class="bx-planos-compra-text31 itens text_plano">
                    <span>Email</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt611" :src="vectorSrc611" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="unionAlt3" :src="unionSrc3" class="icon_plano" />
            <span>
              <slot name="text41">
                <!--Default content for text41-->
                <div class="bx-planos-compra-fragment25">
                  <span class="bx-planos-compra-text55 itens text_plano">
                    <span>WhatsApp</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt21" :src="vectorSrc21" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt31" :src="vectorSrc31" class="icon_plano" />
            <span>
              <slot name="text51">
                <!--Default content for text51-->
                <div class="bx-planos-compra-fragment12">
                  <span class="bx-planos-compra-text30 itens text_plano">
                    Ligação
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt41" :src="vectorSrc41" class="icon_plano" />
          </div>
        </div>
      </div>
      <div class="bx_card_compra bx-planos-compra-card21">
        <div class="bx-planos-compra-card22 card_plano">
          <div class="bx-planos-compra-h22">
            <h3>
              <slot name="text61">
                <!--Default content for text61-->
                <div class="bx-planos-compra-fragment14">
                  <h3 class="bx-planos-compra-text33"><span>Premium</span></h3>
                </div>
              </slot>
            </h3>
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt51" :src="vectorSrc51" class="icon_plano" />
            <span>
              <slot name="text71">
                <!--Default content for text71-->
                <div class="bx-planos-compra-fragment15">
                  <span class="bx-planos-compra-text35 itens text_plano">
                    <span>Email</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt61" :src="vectorSrc61" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="unionAlt11" :src="unionSrc11" class="icon_plano" />
            <span>
              <slot name="text81">
                <!--Default content for text81-->
                <div class="bx-planos-compra-fragment17">
                  <span class="bx-planos-compra-text39 itens text_plano">
                    <span>WhatsApp</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt71" :src="vectorSrc71" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt81" :src="vectorSrc81" class="icon_plano" />
            <span>
              <slot name="text91">
                <!--Default content for text91-->
                <div class="bx-planos-compra-fragment10">
                  <span class="bx-planos-compra-text26 itens text_plano">
                    <span>Ligação</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt91" :src="vectorSrc91" class="icon_plano" />
          </div>
          <div class="list_plano">
            <span>
              <slot name="text141">
                <!--Default content for text141-->
                <div class="bx-planos-compra-fragment11">
                  <span class="bx-planos-compra-text28 text_price">
                    <span>R$ 1,00 / Mês</span>
                  </span>
                </div>
              </slot>
            </span>
          </div>
        </div>
        <div class="bx-planos-compra-btcard1">
          <button
            id="ButtonComprar_1"
            name="ButtonComprar_1"
            onclick="comprar(1)"
            class="bx-planos-compra-button1"
          >
            <span>
              <slot name="text16">
                <!--Default content for text16-->
                <div class="bx-planos-compra-fragment16">
                  <span class="bx-planos-compra-text37 itens">
                    <span>Eu Quero!!</span>
                  </span>
                </div>
              </slot>
            </span>
          </button>
        </div>
      </div>
      <div class="bx_card_compra bx-planos-compra-card31">
        <div class="card_plano bx-planos-compra-card32">
          <div class="bx-planos-compra-h23">
            <h3>
              <slot name="text101">
                <!--Default content for text101-->
                <div class="bx-planos-compra-fragment20">
                  <h3 class="bx-planos-compra-text45"><span>Plus</span></h3>
                </div>
              </slot>
            </h3>
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt101" :src="vectorSrc101" class="icon_plano" />
            <span>
              <slot name="text111">
                <!--Default content for text111-->
                <div class="bx-planos-compra-fragment23">
                  <span class="bx-planos-compra-text51 itens text_plano">
                    <span>Email</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt111" :src="vectorSrc111" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="unionAlt21" :src="unionSrc21" class="icon_plano" />
            <span>
              <slot name="text121">
                <!--Default content for text121-->
                <div class="bx-planos-compra-fragment22">
                  <span class="bx-planos-compra-text49 itens text_plano">
                    <span>WhatsApp</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt121" :src="vectorSrc121" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt131" :src="vectorSrc131" class="icon_plano" />
            <span>
              <slot name="text131">
                <!--Default content for text131-->
                <div class="bx-planos-compra-fragment18">
                  <span class="bx-planos-compra-text41 itens text_plano">
                    <span>Ligação</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt141" :src="vectorSrc141" class="icon_plano" />
          </div>
          <div class="list_plano">
            <span>
              <slot name="text151">
                <!--Default content for text151-->
                <div class="bx-planos-compra-fragment21">
                  <span class="bx-planos-compra-text47 text_price">
                    <span>R$ 50,00 / Mês</span>
                  </span>
                </div>
              </slot>
            </span>
          </div>
        </div>
        <div class="bx-planos-compra-btcard2">
          <button
            id="ButtonComprar_2"
            name="ButtonComprar_2"
            onclick="comprar(2)"
            class="bx-planos-compra-button2"
          >
            <span>
              <slot name="text17">
                <!--Default content for text17-->
                <div class="bx-planos-compra-fragment24">
                  <span class="bx-planos-compra-text53 itens">
                    <span>Eu Quero!!</span>
                  </span>
                </div>
              </slot>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BxPlanosCompra',
  props: {
    vectorSrc15: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/9a41b210-c160-4f65-b8a8-d7b7aac380fe?org_if_sml=1428&force_format=original',
    },
    vectorSrc121: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/c61cacb3-c114-4ca1-8ffc-9fc5b91a0f1a?org_if_sml=1459&force_format=original',
    },
    vectorAlt15: {
      type: String,
      default: 'Vector2024',
    },
    vectorAlt101: {
      type: String,
      default: 'Vector2024',
    },
    vectorSrc111: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/20ef369d-71f6-4f97-80c8-35d946b14c3b?org_if_sml=1459&force_format=original',
    },
    vectorSrc141: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/094fb948-a5a0-4a97-8df2-156b22163d6a?org_if_sml=1459&force_format=original',
    },
    vectorSrc51: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/c9cc96c7-e610-4fb0-8920-f2911763e53a?org_if_sml=1428&force_format=original',
    },
    vectorAlt111: {
      type: String,
      default: 'Vector2024',
    },
    vectorSrc41: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/a3734797-32d7-4aa6-ba51-93183775a208?org_if_sml=1663&force_format=original',
    },
    vectorAlt31: {
      type: String,
      default: 'Vector2024',
    },
    unionSrc21: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/40573c5f-f846-48f1-8000-32c41bf10354?org_if_sml=12526&force_format=original',
    },
    vectorAlt21: {
      type: String,
      default: 'Vector2024',
    },
    vectorSrc31: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/85370a67-db92-451d-bc28-f5d9a541d3e1?org_if_sml=1608&force_format=original',
    },
    unionAlt3: {
      type: String,
      default: 'Union2024',
    },
    vectorAlt121: {
      type: String,
      default: 'Vector2024',
    },
    vectorAlt91: {
      type: String,
      default: 'Vector2024',
    },
    vectorAlt141: {
      type: String,
      default: 'Vector2024',
    },
    vectorSrc61: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/0ca5256b-fa01-43b3-b202-2e20fba9b2db?org_if_sml=1459&force_format=original',
    },
    unionSrc3: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/4d06c892-1094-4493-9207-d252d823a6c8?org_if_sml=12526&force_format=original',
    },
    vectorSrc21: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/339b6ed0-ee75-4909-b484-abbb36a92995?org_if_sml=1670&force_format=original',
    },
    vectorAlt131: {
      type: String,
      default: 'Vector2024',
    },
    unionAlt11: {
      type: String,
      default: 'Union2024',
    },
    vectorSrc131: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/ae6010fb-f836-4630-9127-bdfb472af839?org_if_sml=1588&force_format=original',
    },
    vectorSrc81: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/3f637c5b-5839-4f0e-a3ce-19875fda377d?org_if_sml=1588&force_format=original',
    },
    unionAlt21: {
      type: String,
      default: 'Union2024',
    },
    vectorSrc71: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/cbfcde8d-5210-404e-9abb-e15bec9faa0a?org_if_sml=1459&force_format=original',
    },
    rootClassName: String,
    vectorAlt51: {
      type: String,
      default: 'Vector2024',
    },
    vectorAlt81: {
      type: String,
      default: 'Vector2024',
    },
    vectorSrc101: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/c3bc07e2-03fe-43cf-8c66-5d5b5fac598b?org_if_sml=1428&force_format=original',
    },
    vectorAlt41: {
      type: String,
      default: 'Vector2024',
    },
    unionSrc11: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/bb36f3ac-43c3-4432-a968-fa9bc8381224?org_if_sml=12526&force_format=original',
    },
    vectorAlt71: {
      type: String,
      default: 'Vector2024',
    },
    vectorSrc91: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/756995e7-f615-4e9a-a050-7cc8d155cdbf?org_if_sml=1670&force_format=original',
    },
    vectorAlt61: {
      type: String,
      default: 'Vector2024',
    },
    vectorSrc611: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/960f71c1-d492-4a8a-b034-bbd2ec871a62/0ca5256b-fa01-43b3-b202-2e20fba9b2db?org_if_sml=1459&force_format=original',
    },
    vectorAlt611: {
      type: String,
      default: 'Vector2024',
    },
  },
}
</script>

<style scoped>
.bx-planos-compra-bx-planos-compra {
  gap: 10px;
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  flex-direction: column;
  background-color: rgba(117, 174, 187, 0.30000001192092896);
}
.bx-planos-compra-frame11 {
  width: 100%;
  display: flex;
  align-items: center;
}
.bx-planos-compra-h21 {
  gap: 10px;
  width: 100%;
  height: 25%;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-compra-card22 {
  background-color: rgba(71, 137, 152, 1);
}
.bx-planos-compra-h22 {
  gap: 10px;
  width: 100%;
  height: 25%;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-compra-btcard1 {
  gap: 30px;
  display: flex;
  padding: 10px;
  margin-top: 10px;
  transition: 0.3s;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-compra-btcard1:hover {
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  background-color: #0f7e96;
}
.bx-planos-compra-button1 {
  gap: 0;
  width: 100%;
  height: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
}
.bx-planos-compra-button1:focus {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.bx-planos-compra-button1:hover {
  background-color: #89ccda;
}
.bx-planos-compra-h23 {
  gap: 10px;
  width: 100%;
  height: 25%;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-compra-btcard2 {
  gap: 30px;
  height: auto;
  display: flex;
  padding: 10px;
  margin-top: 10px;
  transition: 0.3s;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-compra-btcard2:hover {
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  background-color: #0f7e96;
}
.bx-planos-compra-button2 {
  gap: 0;
  width: 100%;
  height: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
}
.bx-planos-compra-button2:focus {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.bx-planos-compra-button2:hover {
  background-color: #89ccda;
}
.bx-planos-compra-fragment10 {
  display: contents;
}
.bx-planos-compra-text26 {
  display: inline-block;
}
.bx-planos-compra-fragment11 {
  display: contents;
}
.bx-planos-compra-text28 {
  display: inline-block;
}
.bx-planos-compra-fragment12 {
  display: contents;
}
.bx-planos-compra-text30 {
  display: inline-block;
}
.bx-planos-compra-fragment13 {
  display: contents;
}
.bx-planos-compra-text31 {
  display: inline-block;
}
.bx-planos-compra-fragment14 {
  display: contents;
}
.bx-planos-compra-text33 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.bx-planos-compra-fragment15 {
  display: contents;
}
.bx-planos-compra-text35 {
  display: inline-block;
}
.bx-planos-compra-fragment16 {
  display: contents;
}
.bx-planos-compra-text37 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  text-align: center;
  font-stretch: normal;
}
.bx-planos-compra-fragment17 {
  display: contents;
}
.bx-planos-compra-text39 {
  display: inline-block;
}
.bx-planos-compra-fragment18 {
  display: contents;
}
.bx-planos-compra-text41 {
  display: inline-block;
  font-stretch: normal;
}
.bx-planos-compra-fragment19 {
  display: contents;
}
.bx-planos-compra-text43 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  font-stretch: normal;
  text-decoration: none;
}
.bx-planos-compra-fragment20 {
  display: contents;
}
.bx-planos-compra-text45 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.bx-planos-compra-fragment21 {
  display: contents;
}
.bx-planos-compra-text47 {
  display: inline-block;
}
.bx-planos-compra-fragment22 {
  display: contents;
}
.bx-planos-compra-text49 {
  display: inline-block;
}
.bx-planos-compra-fragment23 {
  display: contents;
}
.bx-planos-compra-text51 {
  display: inline-block;
}
.bx-planos-compra-fragment24 {
  display: contents;
}
.bx-planos-compra-text53 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  text-align: center;
  font-stretch: normal;
}
.bx-planos-compra-fragment25 {
  display: contents;
}
.bx-planos-compra-text55 {
  display: inline-block;
}
.bx-planos-compraroot-class-name {
  display: none;
}
.bx-planos-compraroot-class-name1 {
  height: auto;
  display: none;
}
@media(max-width: 1600px) {
  .bx-planos-compra-bx-planos-compra {
    display: none;
  }
}
@media(max-width: 767px) {
  .bx-planos-compra-text37 {
    font-size: large;
  }
  .bx-planos-compra-text53 {
    font-size: large;
  }
}
@media(max-width: 479px) {
  .bx-planos-compra-bx-planos-compra {
    display: none;
  }
  .bx-planos-compra-card11 {
    display: none;
  }
  .bx-planos-compra-card21 {
    width: 44vw;
    height: 100%;
  }
  .bx-planos-compra-card22 {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .bx-planos-compra-btcard1 {
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 10px;
  }
  .bx-planos-compra-card31 {
    width: 44vw;
    height: 100%;
  }
  .bx-planos-compra-card32 {
    width: 100%;
    height: auto;
  }
  .bx-planos-compra-btcard2 {
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 10px;
  }
  .bx-planos-compra-text37 {
    width: auto;
    height: 100%;
  }
  .bx-planos-compra-text47 {
    margin: auto;
  }
}
</style>
