<template>
  <div class="comprapix-container1">
    <app-menu rootClassName="menuroot-class-name2">
      <template v-slot:text>
        <div class="comprapix-fragment10">
          <span class="comprapix-text10"><span>Home</span></span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="comprapix-fragment11">
          <span class="comprapix-text12"><span>Planos</span></span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="comprapix-fragment12">
          <span class="comprapix-text14"><span>Contato</span></span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="comprapix-fragment13">
          <span class="comprapix-text16"><span>Bloq</span></span>
        </div>
      </template>
    </app-menu>
    <h1 class="comprapix-text18">Pagamento </h1>
    <div class="comprapix-body">
      <h2 id="comprapix-heading" class="comprapix-heading">
        Detalhes do Plano &#123;&#123;plano.title()&#125;&#125;
      </h2>
      <div id="comprapix-container" class="comprapix-container2">
        <div class="comprapix-container3">
          <div class="comprapix-container4">
            <div class="comprapix-container5">
              <h2 class="comprapix-text19">O que esta incluso:</h2>
              <ul class="list">
                <li class="comprapix-li1 list-item">
                  <span>Leitura Diária.</span>
                </li>
                <li class="comprapix-li2 list-item">
                  <span>Aviso Por E-mail</span>
                </li>
                <li class="comprapix-li3 list-item">
                  <span>Aviso por WhatsApp</span>
                </li>
              </ul>
              <h2 class="comprapix-text23">Confirme o seus dados:</h2>
              <ul class="comprapix-ul2 list">
                <li class="comprapix-li4 list-item">
                  <span>Nome Completo: </span>
                  <span>&#123;&#123;nome&#125;&#125;</span>
                </li>
                <li class="comprapix-li5 list-item">
                  <span>Email:</span>
                  <span>&#123;&#123;email&#125;&#125;</span>
                </li>
                <li class="comprapix-li6 list-item">
                  <span>WhatsApp:</span>
                  <span>&#123;&#123;telefone&#125;&#125;</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="comprapix-container6">
            <button
              id="bt_forms"
              type="button"
              onclick="dados_incorretos()"
              class="comprapix-button1 button"
            >
              Mesus dados não está correto.
            </button>
          </div>
        </div>
        <div class="comprapix-container7">
          <img
            alt="Qr Code Pix"
            src="https://fuinomeado.com//get_image/&#123;&#123;hash_user&#125;&#125;.png"
            class="comprapix-image"
          />
          <span>Ou use a chave pix copia e cola</span>
          <button id="bt_copi" type="button" class="comprapix-button2 button">
            Copiar Chave
          </button>
          <textarea
            id="copi_chave"
            rows="5"
            disabled="true"
            class="comprapix-textarea textarea"
          ></textarea>
        </div>
      </div>
      <app-forms rootClassName="formsroot-class-name2">
        <template v-slot:text2>
          <div class="comprapix-fragment14">
            <span class="comprapix-text31"><span>Me avise!</span></span>
          </div>
        </template>
        <template v-slot:text3>
          <div class="comprapix-fragment15">
            <span class="comprapix-text33"><span>Enviar!</span></span>
          </div>
        </template>
        <template v-slot:text4>
          <div class="comprapix-fragment16">
            <span class="comprapix-text35">
              <span> </span>
              <br />
            </span>
          </div>
        </template>
        <template v-slot:text11>
          <div class="comprapix-fragment17">
            <span class="comprapix-text38">
              <span>Por Favor corrija seus dados aqui.</span>
              <br />
            </span>
          </div>
        </template>
        <template v-slot:text41>
          <div class="comprapix-fragment18">
            <span class="comprapix-text41">
              Seu e-mail foi cadastrado com sucesso!
            </span>
          </div>
        </template>
        <template v-slot:text111>
          <div class="comprapix-fragment19">
            <span class="comprapix-text42">
              <span>Você receberá um link de confirmação em breve.</span>
              <br />
            </span>
          </div>
        </template>
      </app-forms>
      <app-footer rootClassName="footerroot-class-name2">
        <template v-slot:text>
          <div class="comprapix-fragment20">
            <span class="comprapix-text45">Text</span>
          </div>
        </template>
        <template v-slot:text1>
          <div class="comprapix-fragment21">
            <span class="comprapix-text46">
              © FuiNomeado.com tem todos os direitos reservados -
              <span v-html="rawv8o5"></span>
            </span>
          </div>
        </template>
        <template v-slot:text2>
          <div class="comprapix-fragment22">
            <span class="comprapix-text47">
              Leia nossa política de privacidade
            </span>
          </div>
        </template>
      </app-footer>
    </div>
    <div>
      <div class="comprapix-container9">
        <DangerousHTML
          html="    <script type='text/javascript' src='/static/js/mkt.js'></script>
      <script type='text/javascript' src='/static/js/pgpagamento.js'></script>"
        ></DangerousHTML>
      </div>
    </div>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import AppMenu from '../components/menu'
import AppForms from '../components/forms'
import AppFooter from '../components/footer'

export default {
  name: 'Comprapix',
  props: {},
  components: {
    AppMenu,
    AppForms,
    AppFooter,
    DangerousHTML,
  },
  data() {
    return {
      rawv8o5: ' ',
    }
  },
  metaInfo: {
    title: 'comprapix - exported project',
    meta: [
      {
        property: 'og:title',
        content: 'comprapix - exported project',
      },
    ],
  },
}
</script>

<style scoped>
.comprapix-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: rgba(214, 214, 214, 0.15);
}
.comprapix-fragment10 {
  display: contents;
}
.comprapix-text10 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.comprapix-fragment11 {
  display: contents;
}
.comprapix-text12 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.comprapix-fragment12 {
  display: contents;
}
.comprapix-text14 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.comprapix-fragment13 {
  display: contents;
}
.comprapix-text16 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.comprapix-text18 {
  fill: var(--dl-color-fuinomeado-azul2);
  color: var(--dl-color-fuinomeado-azul2);
}
.comprapix-body {
  gap: 10px;
  width: 70%;
  height: auto;
  margin: 20px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.comprapix-heading {
  color: var(--dl-color-fuinomeado-azul2);
}
.comprapix-container2 {
  flex: 1;
  height: auto;
  display: flex;
  align-self: stretch;
  box-shadow: 0px 5px 10px 0px #d4d4d4;
  align-items: stretch;
  border-radius: 25px;
  justify-content: space-between;
  background-color: rgba(132, 179, 194, 0.26);
}
.comprapix-container3 {
  flex: 0 0 auto;
  width: 48%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.comprapix-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.comprapix-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.comprapix-text19 {
  fill: var(--dl-color-fuinomeado-azul2);
  color: var(--dl-color-fuinomeado-azul2);
}
.comprapix-li1 {
  fill: var(--dl-color-fuinomeado-azul1);
  color: var(--dl-color-fuinomeado-azul1);
  padding: 2px;
  margin-top: 1px;
}
.comprapix-li2 {
  fill: var(--dl-color-fuinomeado-azul1);
  color: var(--dl-color-fuinomeado-azul1);
  padding: 2px;
  margin-top: 1px;
}
.comprapix-li3 {
  fill: var(--dl-color-fuinomeado-azul1);
  color: var(--dl-color-fuinomeado-azul1);
  padding: 2px;
  margin-top: 1px;
}
.comprapix-text23 {
  fill: var(--dl-color-fuinomeado-azul2);
  color: var(--dl-color-fuinomeado-azul2);
}
.comprapix-ul2 {
  flex: 1;
  width: 100%;
}
.comprapix-li4 {
  fill: var(--dl-color-fuinomeado-azul1);
  color: var(--dl-color-fuinomeado-azul1);
  padding: 2px;
  margin-top: 1px;
}
.comprapix-li5 {
  fill: var(--dl-color-fuinomeado-azul1);
  color: var(--dl-color-fuinomeado-azul1);
  padding: 2px;
  margin-top: 1px;
}
.comprapix-li6 {
  fill: var(--dl-color-fuinomeado-azul1);
  color: var(--dl-color-fuinomeado-azul1);
  padding: 2px;
  margin-top: 1px;
}
.comprapix-container6 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.comprapix-button1 {
  color: #ffffff;
  transition: 0.3s;
  border-color: rgba(117, 174, 187, 0);
  border-radius: 8px;
  background-color: var(--dl-color-fuinomeado-azul1);
}
.comprapix-button1:focus {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.comprapix-button1:hover {
  background-color: var(--dl-color-fuinomeado-azul);
}
.comprapix-container7 {
  flex: 0 0 auto;
  width: 48%;
  height: 100%;
  display: flex;
  box-shadow: 0px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgba(132, 179, 194, 0.3) 43.00%,rgba(78, 135, 149, 0.6) 99.00%);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.comprapix-image {
  width: 200px;
  margin-top: 15px;
  object-fit: cover;
  border-color: #ffffff;
  border-width: 20px;
}
.comprapix-button2 {
  color: #ffffff;
  margin: 10px;
  padding: 10px;
  transition: 0.3s;
  border-color: rgba(117, 174, 187, 0);
  border-width: 0px;
  border-radius: 8px;
  background-color: var(--dl-color-fuinomeado-azul1);
}
.comprapix-button2:focus {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.comprapix-button2:hover {
  background-color: var(--dl-color-fuinomeado-azul);
}
.comprapix-textarea {
  color: #545454;
  width: 100%;
  height: 100%;
  text-align: center;
  border-width: 0px;
  border-radius: 0px;
  background-color: transparent;
}
.comprapix-fragment14 {
  display: contents;
}
.comprapix-text31 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-size: 20px;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.comprapix-fragment15 {
  display: contents;
}
.comprapix-text33 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-size: 12px;
  font-style: Bold;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.comprapix-fragment16 {
  display: contents;
}
.comprapix-text35 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 24px;
  align-self: center;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.comprapix-fragment17 {
  display: contents;
}
.comprapix-text38 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 15px;
  font-style: Semi Bold;
  margin-top: alto;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.comprapix-fragment18 {
  display: contents;
}
.comprapix-text41 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 24px;
  align-self: center;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.comprapix-fragment19 {
  display: contents;
}
.comprapix-text42 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 15px;
  font-style: Semi Bold;
  margin-top: alto;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.comprapix-fragment20 {
  display: contents;
}
.comprapix-text45 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.comprapix-fragment21 {
  display: contents;
}
.comprapix-text46 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.comprapix-fragment22 {
  display: contents;
}
.comprapix-text47 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.comprapix-container9 {
  display: contents;
}
@media(max-width: 1600px) {
  .comprapix-text41 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: Inter;
    font-weight: 700;
    line-height: 28px;
    text-decoration: none;
  }
  .comprapix-text42 {
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 15px;
    text-align: center;
    font-weight: 700;
  }
}
@media(max-width: 1200px) {
  .comprapix-container4 {
    width: 100%;
    padding: 10px;
  }
  .comprapix-container6 {
    justify-content: center;
  }
  .comprapix-button1 {
    margin: var(--dl-space-space-halfunit);
    align-self: center;
  }
  .comprapix-image {
    margin: 15px;
  }
}
@media(max-width: 991px) {
  .comprapix-body {
    height: auto;
  }
}
@media(max-width: 479px) {
  .comprapix-text10 {
    font-style: normal;
  }
  .comprapix-text12 {
    font-style: normal;
  }
  .comprapix-text14 {
    font-style: normal;
  }
  .comprapix-text16 {
    font-style: normal;
  }
  .comprapix-body {
    width: 90%;
  }
  .comprapix-container2 {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .comprapix-container3 {
    width: 100%;
  }
  .comprapix-container7 {
    width: 100%;
  }
  .comprapix-text41 {
    font-size: small;
    font-weight: 800;
  }
  .comprapix-text42 {
    font-size: small;
    font-weight: 500;
  }
}
</style>
